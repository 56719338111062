import { Box, Flex, VStack } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/react';
import { CheckboxControl, SubmitButton } from 'common/forms';
import { SplitScreen } from 'common/layouts';
import { HtmlText, TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import UploadPoa from 'components/Form/UploadPoa';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { createSchema } from 'config';
import { useFormikContext } from 'formik';
import { FileDetailResponse } from 'generated/models';
import useTranslations from 'i18n';
import { useCallback } from 'react';
import gtm from 'trackers/gtm';
import routerPush from 'utils/router';
import scrollToTop from 'utils/scrollToTop';
import Stepper from '../Stepper';
import { AffiliationValues, ExtraWizardProps } from '../types';

const toHome = () => routerPush('/');
const trackSubmit = () => {
  gtm.trackClickContractConfirm();
  scrollToTop();
};
const trackBack = () => gtm.trackClickContractBack();

export const ContractConfirmationStep: WizardStep<WizardStepProps, ExtraWizardProps> = ({
  activeStep,
  campaign,
  config: {
    contractConfirmation: { form: config },
  },
  isIsolated,
  toPrev: toPrevRaw,
}) => {
  const { t, tHtml } = useTranslations();
  const { values, setFieldValue } = useFormikContext<AffiliationValues>();
  const poaRequired = config.powerOfAttorney.visible && values.powerOfAttorneyRequired;
  const handlePoaReset = useCallback(() => {
    setFieldValue('powerOfAttorney', '');
    setFieldValue('powerOfAttorneyFilename', '', false);
  }, [setFieldValue]);
  const handlePoaSuccess = useCallback(
    (response: FileDetailResponse, fileName: string) => {
      setFieldValue('powerOfAttorney', response.file.uid);
      setFieldValue('powerOfAttorneyFilename', fileName, false);
    },
    [setFieldValue],
  );
  const toPrev = useCallback(() => {
    trackBack();
    toPrevRaw();
  }, [toPrevRaw]);

  return (
    <SplitScreen>
      <Flex gap={10} direction="column">
        <Box>
          {!isIsolated && campaign && <CampaignLogo campaign={campaign} />}
          {!isIsolated && <Stepper activeStep={activeStep} />}
          <TextTitle
            mt={isIsolated ? undefined : 6}
            title={t('contract_confirmation.pageHeader', 'Finalize your affiliation')}
            subtitle={t('contract_confirmation.subheader', 'Read and sign your contract')}
          />
        </Box>
        <VStack align="stretch" spacing={4}>
          {config.termsAndConditions.visible && (
            <CheckboxControl
              mandatory={config.termsAndConditions.mandatory}
              name="termsAndConditions"
              id="ContractConfirmationStep_termsAndConditions"
            >
              <HtmlText>
                {tHtml(
                  'contract_confirmation.form.termsAndConditions.terms',
                  'By clicking this checkbox I agree with terms and conditions',
                )}
              </HtmlText>
            </CheckboxControl>
          )}
          {config.gdpr.visible && (
            <CheckboxControl
              mandatory={config.gdpr.mandatory}
              name="gdpr"
              id="ContractConfirmationStep_gdpr"
            >
              <HtmlText>
                {tHtml(
                  'contract_confirmation.form.gdpr.gdpr',
                  'By clicking this checkbox I accept policy and conditions of use.',
                )}
              </HtmlText>
            </CheckboxControl>
          )}
          {poaRequired && (
            <UploadPoa
              mandatory
              name="powerOfAttorney"
              id="ContractConfirmationStep_powerOfAttorney"
              title={t(
                'contract_confirmation.form.powerOfAttorney.description',
                'In case you are not the person who authorized to act to this company please upload the power of attorney',
              )}
              onReset={handlePoaReset}
              onSuccess={handlePoaSuccess}
              uploadedFilename={values.powerOfAttorneyFilename}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <SubmitButton
            variant="primaryFilled"
            id="ContractConfirmationStep__validateButton"
            onClick={isIsolated ? scrollToTop : trackSubmit}
            width="100%"
          >
            {isIsolated
              ? t('global_texts.buttons.confirm', 'Confirm')
              : t('contract_confirmation.buttons.confirm', 'Create contract')}
          </SubmitButton>
          <Button
            variant="primaryOutlined"
            id="ContractConfirmationStep__backButton"
            type="button"
            onClick={isIsolated ? toHome : toPrev}
            width="100%"
            background="semantic.surface.1"
          >
            {isIsolated
              ? t('global_texts.buttons.backHome', 'Back to Homepage')
              : t('global_texts.buttons.back', 'Back')}
          </Button>
        </VStack>
      </Flex>
    </SplitScreen>
  );
};

ContractConfirmationStep.initial = () => ({
  gdpr: false,
  termsAndConditions: false,
  powerOfAttorney: '',
  powerOfAttorneyFilename: '',
  powerOfAttorneyRequired: false,
});

ContractConfirmationStep.schema = ({ config }) => createSchema(config.contractConfirmation.form);

export default ContractConfirmationStep;
