import { Text } from '@chakra-ui/react';
import { Stepper as StepperUI } from '@pluxee-design-system/react';
import useCurrentUser from 'data/useCurrentUser';
import useTranslations from 'i18n';
import { useMemo } from 'react';
import insertIf from 'utils/insertIf';

interface StepperProps {
  activeStep: number;
}

const Stepper = ({ activeStep }: StepperProps) => {
  const { isBOUser } = useCurrentUser();
  const { t } = useTranslations();
  const steps = useMemo(
    () => [
      {
        id: 'CompanyInfoStep',
        title: t('affiliation_flow.companyInfo', 'Company'),
      },
      {
        id: 'ContactInfoStep',
        title: t('affiliation_flow.contactInfo', 'Contact'),
      },
      {
        id: 'ProductSelectionStep',
        title: t('affiliation_flow.productSelection', 'Products'),
      },
      {
        id: 'PaymentInfoStep',
        title: t('affiliation_flow.paymentInfo', 'Payment'),
      },
      ...insertIf(!isBOUser, {
        id: 'ContractConfirmationStep',
        title: t('affiliation_flow.contractConfirmation', 'Contract'),
      }),
      {
        id: 'LocationAddStep',
        title: t('affiliation_flow.locationAdd', 'Location'),
      },
    ],
    [isBOUser, t],
  );
  const activeIndex = Math.min((activeStep > 0 ? activeStep : steps.length) - 1, steps.length - 1);

  return (
    <>
      <Text variant="body.smallBold" mb={2} align="center">
        {steps[activeIndex]?.title}
      </Text>
      <StepperUI index={activeIndex} steps={steps} />
    </>
  );
};

export default Stepper;
