import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/react';
import { useCompanyPositions } from 'api/companyApi';
import {
  CheckboxControl,
  InputControl,
  PhoneNumberControl,
  SelectControl,
  SubmitButton,
} from 'common/forms';
import { SplitScreen } from 'common/layouts';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import { PositionSelect } from 'components/Employees/misc';
import { mapGroupToKey } from 'components/Form';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { createSchema } from 'config';
import useCurrentLocale from 'data/useCurrentLocale';
import useCurrentUser from 'data/useCurrentUser';
import useSession from 'data/useSession';
import { useFormikContext } from 'formik';
import useTranslations from 'i18n';
import { useCallback, useEffect, useMemo } from 'react';
import gtm, { gtmUtils } from 'trackers/gtm';
import scrollToTop from 'utils/scrollToTop';
import Stepper from '../Stepper';
import { AffiliationValues, ExtraWizardProps } from '../types';

interface Language {
  key: string;
  name: string;
}

const selectLanguage = (languages: Language[], locale?: string) =>
  (languages.find((l) => locale?.toLowerCase()?.includes(l.key.toLowerCase())) || languages[0])
    ?.key || '';

const trackSubmit = () => {
  gtm.trackClickContactConfirm(
    gtmUtils.getSelectedOptions('ContactInfoStep_position'),
    gtmUtils.getSelectedOptions('ContactInfoStep_language'),
  );
  scrollToTop();
};
const trackBack = () =>
  gtm.trackClickContactBack(
    gtmUtils.getSelectedOptions('ContactInfoStep_position'),
    gtmUtils.getSelectedOptions('ContactInfoStep_language'),
  );

export const ContactInfoStep: WizardStep<WizardStepProps, ExtraWizardProps, AffiliationValues> = ({
  activeStep,
  campaign,
  config: {
    contactInfo: { form: config },
  },
  toPrev: toPrevRaw,
}) => {
  const [session] = useSession();
  const currentUser = session?.user!;
  const { value: currentLocale } = useCurrentLocale();
  const { isBOUser } = useCurrentUser();
  const { t, tGroup } = useTranslations();
  const { values, setValues } = useFormikContext<AffiliationValues>();
  const isDisabled = isBOUser ? false : !config.signatoryPerson.visible;
  const { data: positions } = useCompanyPositions();
  const toPrev = useCallback(() => {
    trackBack();
    toPrevRaw();
  }, [toPrevRaw]);

  const languages = useMemo(() => mapGroupToKey(tGroup('languages.languages')), [tGroup]);

  useEffect(() => {
    if (values.signatoryPerson) {
      setValues(
        (prevValues) => ({
          ...prevValues,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          phoneNumber: currentUser.telephone,
        }),
      );
    }
  }, [values.signatoryPerson, setValues, currentUser]);

  useEffect(() => {
    setValues(
      (prevValues) => ({
        ...prevValues,
        ...(prevValues.languageSet
          ? {}
          : {
              language: selectLanguage(languages, currentLocale),
              languageSet: true,
            }),
        contactLanguage: selectLanguage(languages, currentUser?.locale),
        // change power of attorney requirement
        powerOfAttorneyRequired:
          positions?.entries?.some((p) => p.id === values.position && p.poaRequired) ?? false,
        contactPosition: positions?.entries?.find((p) => p.defaultOption)?.id ?? null,
      }),
      false,
    );
  }, [
    currentUser?.locale,
    currentLocale,
    languages,
    positions?.entries,
    values.position,
    values.language,
    setValues,
  ]);

  return (
    <SplitScreen>
      <Flex gap={10} direction="column">
        <Box>
          {campaign && <CampaignLogo campaign={campaign} />}
          <Stepper activeStep={activeStep} />
          <TextTitle
            mt={6}
            title={t('contact_info.pageHeader', 'Who is going to be our new partner')}
            subtitle={t(
              'contact_info.subheader',
              'If you are not the person who will be in charge of checking the monthly activity',
            )}
          />
        </Box>
        <VStack align="stretch" spacing={4}>
          {config.signatoryPerson.visible && (
            <CheckboxControl
              mandatory={config.signatoryPerson.mandatory}
              name="signatoryPerson"
              id="ContactInfoStep_signatoryPerson"
              disabled={isBOUser}
            >
              {t(
                'contact_info.form.signatoryPerson.label',
                'Signatory person (Prefill your information)',
              )}
            </CheckboxControl>
          )}
          {(config.lastName.visible || config.firstName.visible) && (
            <SimpleGrid
              columns={config.lastName.visible && config.firstName.visible ? 2 : 1}
              spacing={4}
            >
              {config.firstName.visible && (
                <InputControl
                  type="text"
                  mandatory={config.firstName.mandatory}
                  name="firstName"
                  id="ContactInfoStep_firstName"
                  placeholder={t('contact_info.form.firstName.label', 'First name')}
                  title={t('contact_info.form.firstName.label', 'First name')}
                  disabled={isDisabled || values.signatoryPerson}
                />
              )}
              {config.lastName.visible && (
                <InputControl
                  type="text"
                  mandatory={config.lastName.mandatory}
                  name="lastName"
                  id="ContactInfoStep_lastName"
                  placeholder={t('contact_info.form.lastName.label', 'Last name')}
                  title={t('contact_info.form.lastName.label', 'Last name')}
                  disabled={isDisabled || values.signatoryPerson}
                />
              )}
            </SimpleGrid>
          )}
          {config.email.visible && (
            <InputControl
              type="email"
              mandatory={config.email.mandatory}
              name="email"
              id="ContactInfoStep_email"
              placeholder={t('contact_info.form.email.label', 'Email')}
              title={t('contact_info.form.email.label', 'Email')}
              disabled={isDisabled || values.signatoryPerson}
            />
          )}
          {config.phoneNumber.visible && (
            <PhoneNumberControl
              mandatory={config.phoneNumber.mandatory}
              name="phoneNumber"
              id="ContactInfoStep_phoneNumber"
              title={t('contact_info.form.phoneNumber.label', 'Phone number')}
              disabled={isDisabled || values.signatoryPerson}
            />
          )}
          {config.language.visible && (
            <SelectControl
              mandatory={config.language.mandatory}
              name="language"
              id="ContactInfoStep_language"
              notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
              placeholder={t('contact_info.form.language.placeholder', 'Language')}
              title={t('contact_info.form.language.label', 'Language')}
              options={languages}
            />
          )}
          {config.position.visible && (
            <PositionSelect
              mandatory={config.position.mandatory}
              name="position"
              id="ContactInfoStep_position"
              placeholder={t('contact_info.form.position.placeholder', 'Select your position')}
              title={t('contact_info.form.position.label', 'Position')}
              notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <SubmitButton
            variant="primaryFilled"
            id="ContactInfoStep__validateButton"
            onClick={trackSubmit}
            width="100%"
          >
            {t('global_texts.buttons.confirm', 'Confirm')}
          </SubmitButton>
          <Button
            variant="primaryOutlined"
            id="CompanyInfoStep__backButton"
            type="button"
            onClick={toPrev}
            width="100%"
            background="semantic.surface.1"
          >
            {t('global_texts.buttons.back', 'Back')}
          </Button>
        </VStack>
      </Flex>
    </SplitScreen>
  );
};

ContactInfoStep.initial = () => ({
  firstName: '',
  lastName: '',
  position: null,
  contactPosition: null,
  email: '',
  phoneNumber: '',
  language: '',
  languageSet: false,
  contactLanguage: '',
  signatoryPerson: false,
});

ContactInfoStep.schema = ({ config }) => createSchema(config.contactInfo.form);

export default ContactInfoStep;
