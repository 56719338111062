import { Box, Flex, VStack } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/react';
import { InputControl, SubmitButton } from 'common/forms';
import { SplitScreen } from 'common/layouts';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import { BankSelect } from 'components/Form';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { createSchema } from 'config';
import useTranslations from 'i18n';
import { useCallback } from 'react';
import gtm from 'trackers/gtm';
import scrollToTop from 'utils/scrollToTop';
import Stepper from '../Stepper';
import { ExtraWizardProps } from '../types';

const trackSubmit = () => {
  gtm.trackClickPaymentConfirm();
  scrollToTop();
};
const trackBack = () => gtm.trackClickPaymentBack();

export const PaymentInfoStep: WizardStep<WizardStepProps, ExtraWizardProps> = ({
  activeStep,
  campaign,
  config: {
    paymentInfo: { form: config },
  },
  toPrev: toPrevRaw,
}) => {
  const { t } = useTranslations();
  const toPrev = useCallback(() => {
    trackBack();
    toPrevRaw();
  }, [toPrevRaw]);

  return (
    <SplitScreen>
      <Flex gap={10} direction="column">
        <Box>
          {campaign && <CampaignLogo campaign={campaign} />}
          <Stepper activeStep={activeStep} />
          <TextTitle
            mt={6}
            title={t('payment_info.pageHeader', 'We need some information about payment')}
          />
        </Box>
        <VStack align="stretch" spacing={4}>
          {config.bank.visible && (
            <BankSelect
              mandatory={config.bank.mandatory}
              name="bank"
              id="PaymentInfoStep_bank"
              placeholder={t('payment_info.form.bank.label', 'Select bank')}
              title={t('payment_info.form.bank.label', 'Select bank')}
              notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
            />
          )}
          {(config.bankNumber.visible || config.accountNumber.visible) && (
            <Flex gap={4}>
              {config.bankNumber.visible && (
                <InputControl
                  type="text"
                  mandatory={config.bankNumber.mandatory}
                  name="bankNumber"
                  id="PaymentInfoStep_bankNumber"
                  placeholder={t('payment_info.form.bankNumber.label', 'Bank number prefix')}
                  title={t('payment_info.form.bankNumber.label', 'Bank number prefix')}
                />
              )}
              {config.accountNumber.visible && (
                <Box flex="2 0 auto">
                  <InputControl
                    type="text"
                    mandatory={config.accountNumber.mandatory}
                    name="accountNumber"
                    id="PaymentInfoStep_accountNumber"
                    placeholder={t('payment_info.form.accountNumber.label', 'Account number')}
                    title={t('payment_info.form.accountNumber.label', 'Account number')}
                  />
                </Box>
              )}
            </Flex>
          )}
          {config.invoiceEmail.visible && (
            <InputControl
              type="email"
              mandatory={config.invoiceEmail.mandatory}
              name="invoiceEmail"
              id="PaymentInfoStep_invoiceEmail"
              placeholder={t('payment_info.form.invoiceEmail.label', 'Invoicing email')}
              title={t('payment_info.form.invoiceEmail.label', 'Invoicing email')}
            />
          )}
          {config.poReferenceNumber.visible && (
            <InputControl
              type="text"
              mandatory={config.poReferenceNumber.mandatory}
              name="poReferenceNumber"
              id="PaymentInfoStep_poReferenceNumber"
              placeholder={t(
                'payment_info.form.poReferenceNumber.label',
                'Purchase order reference number',
              )}
              title={t(
                'payment_info.form.poReferenceNumber.label',
                'Purchase order reference number',
              )}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <SubmitButton
            variant="primaryFilled"
            id="PaymentInfoStep__validateButton"
            onClick={trackSubmit}
            width="100%"
          >
            {t('global_texts.buttons.confirm', 'Confirm')}
          </SubmitButton>
          <Button
            variant="primaryOutlined"
            id="PaymentInfoStep__backButton"
            type="button"
            onClick={toPrev}
            width="100%"
            background="semantic.surface.1"
          >
            {t('global_texts.buttons.back', 'Back')}
          </Button>
        </VStack>
      </Flex>
    </SplitScreen>
  );
};

PaymentInfoStep.initial = () => ({
  bank: '',
  bankNumber: '',
  accountNumber: '',
  invoiceEmail: '',
  poReferenceNumber: '',
});

PaymentInfoStep.schema = ({ config }) => createSchema(config.paymentInfo.form);

export default PaymentInfoStep;
