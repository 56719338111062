import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/react';
import { useCompanyServices } from 'api/companyApi';
import {
  AddressControl,
  CheckboxControl,
  CompanyIdControl,
  InputControl,
  SubmitButton,
} from 'common/forms';
import { SplitScreen } from 'common/layouts';
import { TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import { WizardStep, WizardStepProps } from 'components/WizardForm/types';
import { companyIdAsync, createSchema, getValidationErrors } from 'config';
import { useFormikContext } from 'formik';
import useTranslations from 'i18n';
import { intersection } from 'lodash';
import { useCallback, useEffect } from 'react';
import gtm from 'trackers/gtm';
import scrollToTop from 'utils/scrollToTop';
import { headerTranslationKeys, PrefillStatusEnum } from '../prefillStatus';
import Stepper from '../Stepper';
import { AffiliationValues, ExtraWizardProps } from '../types';
import { CompanyServiceSelect, LegalFormSelectControl } from './utils';

export const CompanyInfoStep: WizardStep<WizardStepProps, ExtraWizardProps, AffiliationValues> = ({
  activeStep,
  campaign,
  config: {
    companyInfo: { form: config },
  },
  nextStepProps: { prefillAddressIncomplete, prefillDataStatus },
  toPrev: toPrevRaw,
}) => {
  const { data: servicesData } = useCompanyServices();
  const services = servicesData?.entries;
  const { t } = useTranslations();
  const { setFieldValue, values } = useFormikContext<AffiliationValues>();
  const prefillStatus = (prefillDataStatus || PrefillStatusEnum.Skipped) as PrefillStatusEnum;
  const isCompanyInfoPrefilled = prefillStatus === PrefillStatusEnum.Prefilled;
  const isAddressDisabled = values.country === 'be' && !prefillAddressIncomplete;

  const toPrev = useCallback(() => {
    gtm.trackClickCompanyBack(Object.values(values.activityNames));
    toPrevRaw();
  }, [toPrevRaw, values.activityNames]);
  const trackSubmit = useCallback(() => {
    gtm.trackClickCompanyConfirm(Object.values(values.activityNames));
  }, [values.activityNames]);

  useEffect(() => {
    setFieldValue(
      'activityNames',
      (services || []).reduce((result, service) => {
        if (values.activity.includes(service.code)) {
          result[service.code] = t(
            `company_services.companyServices.*.${service.nameKey}.name`,
            service.name,
          );
        }
        return result;
      }, {} as Record<string, string>),
      false,
    );
  }, [setFieldValue, services, values.activity, t]);

  return (
    <SplitScreen>
      <Flex gap={10} direction="column">
        <Box>
          {campaign && <CampaignLogo campaign={campaign} />}
          <Stepper activeStep={activeStep} />
          <TextTitle
            mt={6}
            title={t(
              headerTranslationKeys[prefillStatus].header.key,
              headerTranslationKeys[prefillStatus].header.defaultValue,
            )}
            subtitle={t(
              headerTranslationKeys[prefillStatus].subHeader.key,
              headerTranslationKeys[prefillStatus].subHeader.defaultValue,
            )}
          />
        </Box>
        <VStack align="stretch" spacing={4}>
          {config.commercialName.visible && (
            <InputControl
              type="text"
              mandatory={config.commercialName.mandatory}
              name="commercialName"
              id="CompanyInfoStep_commercialName"
              placeholder={t('company_info.form.commercialName.label', 'Commercial name')}
              title={t('company_info.form.commercialName.label', 'Commercial name')}
            />
          )}
          {(config.companyName.visible || config.legalForm.visible) && (
            <SimpleGrid
              columns={config.companyName.visible && config.legalForm.visible ? 2 : 1}
              spacing={4}
            >
              {config.companyName.visible && (
                <InputControl
                  type="text"
                  mandatory={config.companyName.mandatory}
                  name="companyName"
                  id="CompanyInfoStep_companyName"
                  placeholder={t('company_info.form.companyName.label', 'Company name')}
                  title={t('company_info.form.companyName.label', 'Company name')}
                  disabled={Boolean(isCompanyInfoPrefilled && values.companyName)}
                />
              )}
              {config.legalForm.visible && (
                <LegalFormSelectControl
                  mandatory={config.legalForm.mandatory}
                  name="legalForm"
                  id="CompanyInfoStep_legalForm"
                  placeholder={t('company_info.form.legalForm.label', 'Legal form')}
                  title={t('company_info.form.legalForm.label', 'Legal form')}
                  notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
                  disabled={Boolean(isCompanyInfoPrefilled && values.legalForm)}
                />
              )}
            </SimpleGrid>
          )}
          <AddressControl
            config={config}
            disabled={isAddressDisabled}
            idPrefix="CompanyInfoStep"
            tPrefix="company_info.form"
          />
          {(config.companyId.visible || config.courtFileNumber.visible) && (
            <SimpleGrid
              columns={config.companyId.visible && config.courtFileNumber.visible ? 2 : 1}
              spacing={4}
            >
              {config.companyId.visible && (
                <CompanyIdControl
                  mandatory={config.companyId.mandatory}
                  name="companyId"
                  id="CompanyInfoStep_companyId"
                  placeholder={t('company_id.form.companyId.placeholder', '12345678')}
                  title={t('company_info.form.companyId.label', 'Company ID')}
                  disabled={Boolean(isCompanyInfoPrefilled && values.companyId)}
                  format={config.companyId.format}
                />
              )}
              {config.courtFileNumber.visible && (
                <InputControl
                  type="text"
                  mandatory={config.courtFileNumber.mandatory}
                  name="courtFileNumber"
                  id="CompanyInfoStep_courtFileNumber"
                  placeholder={t('company_info.form.courtFileNumber.label', 'Court file number')}
                  title={t('company_info.form.courtFileNumber.label', 'Court file number')}
                />
              )}
            </SimpleGrid>
          )}
          {config.vatPayer.visible && (
            <CheckboxControl
              mandatory={config.vatPayer.mandatory}
              name="vatPayer"
              id="CompanyInfoStep_vatPayer"
            >
              {t('company_info.form.vatPayer.label', 'VAT payer')}
            </CheckboxControl>
          )}
          {config.vatNumber.visible && (
            <InputControl
              type="text"
              mandatory={config.vatNumber.mandatory || values.vatPayer}
              name="vatNumber"
              id="CompanyInfoStep_vatNumber"
              placeholder={t('company_info.form.vatNumber.label', 'VAT number')}
              title={t('company_info.form.vatNumber.label', 'VAT number')}
              disabled={!values.vatPayer}
            />
          )}
          {config.activity.visible && (
            <CompanyServiceSelect
              variant={config.activity.format !== 'SINGLE' ? 'multiple' : 'single'}
              mandatory={config.activity.mandatory}
              name="activity"
              id="CompanyInfoStep_activity"
              placeholder={t('company_info.sectionTexts.activityHeader', 'Activity')}
              title={t('company_info.sectionTexts.activityHeader', 'Activity')}
              notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
            />
          )}
          {campaign?.isActive && campaign?.config?.visible && (
            <InputControl
              type="text"
              mandatory={campaign?.config?.mandatory}
              name="campaignMemberId"
              id="CompanyInfoStep_campaignMemberId"
              placeholder={campaign?.config?.placeholder || 'Member ID'}
              title={campaign?.config?.placeholder || 'Member ID'}
            />
          )}
        </VStack>
        <VStack align="stretch" spacing={4}>
          <SubmitButton
            variant="primaryFilled"
            id="CompanyInfoStep__validateButton"
            onClick={trackSubmit}
            width="100%"
          >
            {t('global_texts.buttons.confirm', 'Confirm')}
          </SubmitButton>
          <Button
            variant="primaryOutlined"
            id="CompanyInfoStep__backButton"
            type="button"
            onClick={toPrev}
            width="100%"
            background="semantic.surface.1"
          >
            {t('global_texts.buttons.back', 'Back')}
          </Button>
        </VStack>
      </Flex>
    </SplitScreen>
  );
};

CompanyInfoStep.initial = () => ({
  companyName: '',
  commercialName: '',
  legalForm: '',
  streetName: '',
  box: '',
  houseNumber: '',
  activity: [],
  activityNames: {},
  postalCode: '',
  city: '',
  vatPayer: false,
  vatNumber: '',
  companyId: '',
  courtFileNumber: '',
  campaignMemberId: '',
});

CompanyInfoStep.validate =
  ({ campaign, config }) =>
  async (values) => {
    const errors: Record<string, any> = {};

    try {
      let syncSchema = createSchema(config.companyInfo.form);
      if (campaign && campaign?.isActive) {
        syncSchema = syncSchema.concat(createSchema({ campaignMemberId: campaign.config }));
      }
      await syncSchema.validateSync(values, { abortEarly: false });
    } catch (validationErrors) {
      // abortEarly = false, must access inner
      getValidationErrors(validationErrors.inner, errors);
    }

    return errors;
  };

CompanyInfoStep.validateSubmit = async (values, { callCache, config }) => {
  const errors: Record<string, any> = {};

  try {
    const asyncSchema = companyIdAsync(callCache, config.companyId.form.companyId.visible);
    await asyncSchema.validate(values);
  } catch (error) {
    getValidationErrors([error], errors);
  }

  return errors;
};

CompanyInfoStep.onSubmit = (values, { setFieldValue }, _setNextStepProps, { products }) => {
  setFieldValue(
    'products',
    values.products.filter((selectedProduct) => {
      const product = products?.find((p) => p.uid === selectedProduct.productUid);
      return (
        product !== undefined &&
        intersection(values.activity, product.services).length > 0 &&
        (product.payoutList
          ? product.payoutList.some((py) =>
              py.channelList.some((ch) => ch.payoutUid === selectedProduct.payoutUid),
            )
          : true)
      );
    }),
    false,
  );
  scrollToTop();
  return Promise.resolve();
};

export default CompanyInfoStep;
